import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'

import { Row, Col, Button, Radio, Popover } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'

import {
  colorDark,
  colorLight,
  colorNeutral,
  colorPrimary,
  colorPrimaryDark,
  colorNeutralLight,
} from '../utils/colors'
import WebContainer from '../components/webcontainer'
import SEO from '../components/SEO'
import CtaForm1 from '../components/ctaform1'
import Brands from '../images/brands.png'
import WebsiteFooter from '../components/WebsiteFooter'
import { getAuthState } from '../utils/auth'
import WebsiteHeader from '../components/WebsiteHeader'

const PricingPage = () => {
  const authState = getAuthState()
  const [isYearlyPlan, setIsYearlyPlan] = useState(true)

  useEffect(() => {
    if(window.location.host.includes("app")) {
      window.location.href = ('/dashboard'); // Redirect to the desired route
     }
}, []);

  const ConnectionsExplanation = (
    <div>
      <p>
        Each sync connection is one-directional. <br />
        Connecting two calendars in both directions requires 2 sync connections.
        <br />
        <a href="https://help.calendarbridge.com/faq/how-many-sync-connections-do-i-need/">
          How many sync connections do I need?
        </a>
      </p>
    </div>
  )

  const AccountsExplanation = (
    <div>
      <p>
        Basic, Premium, and Pro plans are limited to 4 accounts from any single
        domain.
        <br /> Connecting 5 or more accounts from a single domain requires a
        group plan.
        <br />
        <a href="https://help.calendarbridge.com/faq/do-i-need-a-group-plan/">
          Do I need a group plan?
        </a>
      </p>
    </div>
  )
  const unselected =
    'pl-4 pr-4 pt-2 pb-2 shadow-md border-2 rounded-sm border-brand_dark_blue text-brand_dark_blue'

  const selected =
    'pl-4 pr-4 pt-2 pb-2  shadow-md border-2 rounded-sm border-brand_dark_blue bg-brand_dark_blue text-white'
  return (
    <>
      <SEO
        title="Plans &amp; Pricing"
        description={
          'Calendar syncing and scheduling starts at only $4 a month, with a free 7-day trial at signup. Special pricing available for group plans.'
        }
      />
      <div className="flex flex-col justify-center">
        <div className="bg-creme grid grid-cols-1">
          <WebsiteHeader authState={authState}></WebsiteHeader>
          <div className="flex justify-center mt-10 mb-10 text-brand_dark_blue text-lg lg:text-5xl font-lato">
            Plans And Pricing
          </div>
        </div>
        <div className=" flex flex-col justify-center mt-10 mb-10">
          <div className=" flex justify-center lg:mb-10 font-lato">
            <div className="flex flex-row">
              <div
                onClick={(e) => setIsYearlyPlan(false)}
                className={isYearlyPlan ? unselected : selected}
              >
                Billed Monthly
              </div>
              <div
                onClick={(e) => setIsYearlyPlan(true)}
                className={!isYearlyPlan ? unselected : selected}
              >
                Billed Yearly
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center ">
          <div className="grid grid-cols-1 lg:grid-cols-6 gap-4 lg:items-center lg:self-center justify-center pr-2 pl-2 lg:pl-0 lg:pr-0">
            <div className="flex flex-col lg:col-span-2">
              <div className="bg-creme shadow-md rounded-t-lg flex justify-between items-center p-4">
                <div className="text-2xl font-lato text-darkblue_highlight">
                  Basic
                </div>
                <div>
                  <span
                    style={{
                      fontSize: '2em',
                      fontWeight: 'bold',
                      color: '#09467D',
                    }}
                  >
                    {' '}
                    ${isYearlyPlan ? 4 : 5}
                  </span>
                  /user/mo <br />
                </div>
              </div>
              <div className="bg-white rounded-b-md shadow-md p-4">
                <div className="flex flex-col space-y-4">
                  <div>
                    <b>2 Always-On Sync Connections</b>
                  </div>
                  <div>10 Scheduling Links</div>
                  <div>Unlimited Connected Accounts</div>
                  <div>Zoom, Teams, Meet, and Skype Integrations</div>
                  <div>Personalized scheduling URLs</div>
                </div>
              </div>
            </div>
            {/* PREMUIM */}
            <div className="justify-self-auto  flex flex-col lg:col-span-2">
              <div className="bg-creme rounded-t-lg flex justify-between items-center p-4">
                <div className="text-2xl font-lato text-darkblue_highlight">
                  Premium
                </div>
                <div>
                  <span
                    style={{
                      fontSize: '2em',
                      fontWeight: 'bold',
                      color: '#09467D',
                    }}
                  >
                    {' '}
                    ${isYearlyPlan ? 8 : 10}
                  </span>
                  /user/mo <br />
                </div>
              </div>
              <div className="bg-white rounded-b-md shadow-md p-4">
                <div className="flex flex-col space-y-4">
                  <div>
                    <b>16 Always-On Sync Connections</b>
                  </div>
                  <div>10 Scheduling Links</div>
                  <div>Unlimited Connected Accounts</div>
                  <div>Zoom, Teams, Meet, and Skype Integrations</div>
                  <div>Personalized scheduling URLs</div>
                </div>
              </div>
            </div>
            <div className="justify-self-auto  flex flex-col lg:col-span-2">
              <div className="bg-creme rounded-t-lg flex justify-between items-center p-4">
                <div className="text-2xl font-lato text-darkblue_highlight">
                  Pro
                </div>
                <div>
                  <span
                    style={{
                      fontSize: '2em',
                      fontWeight: 'bold',
                      color: '#09467D',
                    }}
                  >
                    {' '}
                    ${isYearlyPlan ? 32 : 40}
                  </span>
                  /user/mo <br />
                </div>
              </div>
              <div className="bg-white rounded-b-md shadow-md p-4">
                <div className="flex flex-col space-y-4">
                  <div>
                    <b>36 Always-On Sync Connections</b>
                  </div>
                  <div>10 Scheduling Links</div>
                  <div>Unlimited Connected Accounts</div>
                  <div>Zoom, Teams, Meet, and Skype Integrations</div>
                  <div>Personalized scheduling URLs</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:col-span-6">
              <div className="rounded-t-lg flex justify-center items-center p-4">
                <div className="text-2xl font-lato text-darkblue_highlight">
                  Group Plans
                </div>
              </div>
              {/* <div className="bg-white flex flex-col justify-center rounded-b-md shadow-md p-4">
                <div className="text-bold flex justify-center">
                  Customizable plans for 5 or more users
                </div>
                <div className="flex justify-center mt-5 mb-5">
                  <a
                    href="https://help.calendarbridge.com/group-plan-inquiry/"
                    target="_blank"
                    className="cursor-pointer text-white bg-burnt hover:text-white rounded-sm pl-4 pr-4 pt-2 pb-2"
                  >
                    Contact Us
                  </a>
                </div>
              </div> */}
            </div>
            <div className="justify-self-auto  flex flex-col lg:col-span-3">
                <div className="bg-creme rounded-t-lg flex justify-between items-center p-4">
                  <div className="text-2xl font-lato text-darkblue_highlight">
                    Per-User Licensing
                  </div>

                </div>
                <div className="bg-white rounded-b-md shadow-md p-4">
                  <div className="flex flex-col space-y-4">
                    <div>
                      <b>Admins assign licenses. Users setup their own accounts.</b>
                    </div>
                    <div>Pricing starts as listed for individual accounts.</div>
                    <div>Mix & match Basic, Premium, & Pro licenses, as needed.</div>
                    <div>Self signup available (<a style={{textDecoration: 'underline'}} href="https://help.calendarbridge.com/support/group-account-management/creating-a-group-account/">Here's how.</a>)</div>
                    <div>Have 25+ users? <a style={{textDecoration: 'underline'}} href="https://help.calendarbridge.com/group-plans/">Contact us</a> for special pricing.</div>
                  </div>
                </div>
              </div>
              <div className="justify-self-auto  flex flex-col lg:col-span-3">
                <div className="bg-creme rounded-t-lg flex justify-between items-center p-4">
                  <div className="text-2xl font-lato text-darkblue_highlight">
                    Admin-Managed Syncs
                  </div>

                </div>
                <div className="bg-white rounded-b-md shadow-md p-4">
                  <div className="flex flex-col space-y-4">
                    <div>
                      <b>Admins create sync connections with no need to involve end users.</b>
                    </div>
                    <div>Pricing starts at ${isYearlyPlan ? "3.20" : "4"}/sync/mo.</div>
                    <div>Create sync connections via the admin dashboard or a spreadsheet.</div>
                    <div>Self signup available (<a style={{textDecoration: 'underline'}} href="https://help.calendarbridge.com/support/group-account-management/creating-a-group-account/">Here's how.</a>)</div>
                    <div>Need 25+ sync connections? <a style={{textDecoration: 'underline'}} href="https://help.calendarbridge.com/group-plans/">Contact us</a> for special pricing.</div>
                  </div>
                </div>
              </div>
          </div>

          <div className="flex justify-center mt-20 mb-8 text-brand_dark_blue text-lg lg:text-5xl font-lato">
            You're in good company
          </div>
          <div className="flex justify-center mt-10 mb-20 text-brand_dark_blue text-lg lg:text-5xl font-lato">
            <img width="800" src={Brands}></img>
          </div>

          <div className="bg-brand_dark_blue">
            <div className="flex justify-center mt-20 mb-5 text-white text-lg lg:text-5xl font-lato">
              Start your free trial today
            </div>
            <div className="flex justify-center mt-10 mb-20 ">
              <CtaForm1 type="blue" referral="pricing"></CtaForm1>
            </div>
          </div>
        </div>
      </div>
      <WebsiteFooter></WebsiteFooter>
    </>
  )
}

const styles = {
  main: {
    maxWidth: 800,
    margin: '0 auto',
    padding: '0 20px',
  },

  header: {
    fontWeight: 'bold',
  },

  subHeader: {
    fontWeight: 300,
    marginBottom: 20,
    marginTop: 10,
  },

  footer: {
    backgroundColor: colorDark,
    padding: 20,
    color: colorLight,
  },
  footerInner: {
    maxWidth: 800,
    margin: '0 auto',
  },
  footerLink: {
    marginRight: 10,
  },
  footerCopy: {
    float: 'right',
    color: colorNeutral,
  },

  emailField: {
    padding: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  emailButton: {
    fontWeight: 'bold',
  },

  bigParagraph: {
    fontSize: 24,
  },
  mediumParagraph: {
    fontSize: 18,
  },

  blueBox: {
    padding: 20,
    backgroundColor: colorPrimary,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colorPrimaryDark,
    borderRadius: 5,
    textAlign: 'left',
    height: '300px',
  },
  blueBoxHeader: {
    color: colorLight,
    fontWeight: 'bold',
  },
  blueBoxBody: {
    color: colorLight,
    fontSize: 16,
  },
  blueBoxAction: {
    textAlign: 'center',
    color: colorLight,
    fontWeight: 'bold',
    margin: 0,
    fontSize: 20,
  },
  grayBox: {
    padding: 16,
    backgroundColor: colorNeutralLight,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colorNeutral,
    borderRadius: 5,
    textAlign: 'left',
    height: 300,
    lineHeight: '1.1em',
  },
  grayBoxHeader: {
    color: colorDark,
    fontWeight: 'bold',
  },
  grayBoxBody: {
    color: colorDark,
    fontSize: 16,
    marginTop: 10,
  },
  grayBoxAction: {
    textAlign: 'center',
    color: colorDark,
    fontWeight: 'bold',
    margin: 0,
    fontSize: 20,
  },
}

export default PricingPage
